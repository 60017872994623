/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function BonLookFAQ({ noHeader, lang }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
  };

  const faq = [
    {
      id: 1,
      title: 'Where can I track my progress?',
      answer: 'You can track your progress by logging into your Trendsetter account and going to your benefits page.',
    },
    {
      id: 2,
      title: 'How is my membership status determined?',
      answer: 'Your Trendsetter tier is determined by how many pairs of frames you purchase and how much you spend each calendar year. You can make these purchases at bonlook.ca or at one of our retail locations.',
    },
    {
      id: 3,
      title: 'How does earning work with the BonLook Trendsetter Program?',
      answer: 'It’s easy: the more you shop, the closer you get to leveling up to a new tier and accessing more perks.',
    },
    {
      id: 4,
      title: 'What happens to my rewards if I return something?',
      answer: 'When you return an item, that amount will be deducted from your yearly spending total.',
    },
    {
      id: 5,
      title: 'How do I add my birthday to my account to redeem my birthday gift?',
      answer: 'Go on bonlook.ca, sign into <a href="https://www.bonlook.ca/account/login?return_url=%2Faccount" target="_blank">“My Account.”</a> Into your profile, click on “Edit your information” and add your date of birth.',
    },
    {
      id: 6,
      title: 'Terms and conditions',
      answer: '<a href="https://www.bonlook.ca/pages/trendsetter-program-terms" target="_blank">Click here</a> to see the terms and conditions of all offers.',
    },
  ];

  useEffect(() => {
    setQuestions(faq);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader -center">FAQ</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default BonLookFAQ;
